import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  StyledSelect,
  FormControl,
  StyledInput,
} from "../../Form/FormControls";
import { ColumnModifiers } from "./buildingBlocks";
import { VscBeaker } from "react-icons/vsc";

const NodeBody = styled.div`
  padding: 0.5em;
  flex: 1;
  background: rgba(0, 0, 0, 0.03);
`;

const SourceComponent = ({
  node,
  dispatch,
  index,
  enableTest,
  testRow,
  goodTestParse,
}) => {
  const SourceOptions = [{ label: "Column", value: 1 }];

  let testRowDataOptions = [];

  try {
    if (testRow && goodTestParse) {
      testRowDataOptions = JSON.parse(testRow);
    }
  } catch (e) {
    testRowDataOptions = [];
  }

  const testOptions = testRowDataOptions.map((to, i) => {
    return {
      label: to,
      value: i,
    };
  });

  const currentOperation = node.operation.typeInformation[1].typeValue?.value;

  const acceptableOperationsModifiers = [1, 2, 3, 4, 5, 14, 15];

  const showModifier = currentOperation
    ? acceptableOperationsModifiers.includes(currentOperation)
    : null;

  const sourceModifier = node.source.typeInformation.find(
    (ti) => ti?.typeHierarchyLevel === 2
  )?.typeValue;

  const [enableColumnAttribute, setEnableColumnAttribute] = useState(
    node.source.typeInformation.find((ti) => ti?.typeHierarchyLevel === 2)
      ? true
      : false
  );

  useEffect(() => {
    if (enableColumnAttribute) {
      dispatch({
        type: "UPDATE_MODIFIER_SOURCE",
        payload: {
          node: node,
          value: sourceModifier ?? ColumnModifiers[0],
          index: index,
          fragmentValue: node?.source?.typeInformation[2]?.fragmentValue,
        },
      });
    } else {
      dispatch({
        type: "REMOVE_MODIFIER_SOURCE",
        payload: {
          node: node,
          value: ColumnModifiers[0],
          index: index,
        },
      });
    }
    // eslint-disable-next-line
  }, [enableColumnAttribute, dispatch, sourceModifier]);

  return (
    <>
      <NodeBody>
        <FormControl>
          <StyledSelect
            aria-label="fragmentColumnSelect"
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            name={`source`}
            id={`source`}
            inputId={`sourceSelect-input`}
            instanceId={`sourceSelect-instance`}
            label="Source"
            options={SourceOptions}
            placeholder={`Select Source Type`}
            menuPortalTarget={document.body}
            value={node.source.typeInformation[1].typeValue}
            onChange={(e) =>
              dispatch({
                type: "UPDATE_SOURCE_TYPE",
                payload: { node: node, value: e, index: index },
              })
            }
          />
        </FormControl>
        <FormControl>
          <StyledInput
            type="text"
            name="source-value"
            value={node.source.typeInformation[1].fragmentValue}
            placeholder="Column Name"
            onChange={(e) =>
              dispatch({
                type: "UPDATE_SOURCE_NAME",
                payload: { node: node, value: e.target?.value, index: index },
              })
            }
          />
        </FormControl>
        {showModifier && (
          <>
            <FormControl>
              <label>
                Use Modifier
                <input
                  type="checkbox"
                  checked={enableColumnAttribute}
                  onChange={() => {
                    setEnableColumnAttribute((prevState) => !prevState);
                  }}
                />
              </label>
            </FormControl>
          </>
        )}

        {enableTest ? (
          <FormControl>
            <VscBeaker /> Test Value{" "}
            <StyledSelect
              aria-label="fragmentColumnSelect"
              className={`react-select-container`}
              classNamePrefix={`react-select`}
              name={`testInfo`}
              id={`testInfo`}
              inputId={`testInfoSelect-input`}
              instanceId={`testInfoSelect-instance`}
              label="Source"
              options={testOptions}
              placeholder={`Select Test Value`}
              value={node.source.testInfo}
              menuPortalTarget={document.body}
              onChange={(e) =>
                dispatch({
                  type: "UPDATE_TEST_VALUE",
                  payload: { node: node, value: e, index: index },
                })
              }
            />
          </FormControl>
        ) : null}

        {enableColumnAttribute && showModifier ? (
          <>
            <FormControl>
              <StyledSelect
                aria-label="fragmentColumnSelect"
                className={`react-select-container`}
                classNamePrefix={`react-select`}
                name={`source`}
                id={`source`}
                inputId={`sourceSelect-input`}
                instanceId={`sourceSelect-instance`}
                label="Source"
                options={ColumnModifiers}
                placeholder={`Select Source Modifier`}
                value={sourceModifier}
                menuPortalTarget={document.body}
                onChange={(e) =>
                  dispatch({
                    type: "UPDATE_MODIFIER_SOURCE",
                    payload: { node: node, value: e, index: index },
                  })
                }
              />
            </FormControl>

            {node.source?.typeInformation[2]?.typeHierarchyLevel === 2 &&
            node.source?.typeInformation[2]?.typeValue?.value === 2 ? (
              <FormControl>
                <StyledInput
                  type="number"
                  name="variable-modifier-value"
                  value={node?.source?.typeInformation[2]?.fragmentValue}
                  placeholder="Modifier Value"
                  label="Number of days to add (to subtract, use a negative value)"
                  onChange={(e) =>
                    dispatch({
                      type: "UPDATE_SOURCE_MODIFIER_VALUE",
                      payload: {
                        node: node,
                        value: e.target?.value,
                        index: index,
                      },
                    })
                  }
                />
              </FormControl>
            ) : null}
          </>
        ) : null}
      </NodeBody>
    </>
  );
};

export default SourceComponent;
