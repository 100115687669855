import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import {
  FormControl,
  StyledField,
  FormActions,
  StyledSelect,
  ErrorMessage,
  Label,
} from "../../Form/FormControls";
import Button from "../../Button";
import Spinner from "../../Loaders/Spinner";
import Modal from "../../Modal";
import useGraphApi from "../../../api/useGraphApi";
import { setDataSourceRemoteInformation } from "../../../api/dataSourceMutations";
import * as Yup from "yup";
import styled from "styled-components/macro";
import ErrorMessages from "../../Notifications/ErrorMessages";
import Copy from "../../Copy";
import SelectEgressForm from "./EgressSelectFormSection";
import StyledLink from "../../StyledLink";
import { MdOutlineHelp } from "react-icons/md";
import MarkdownFetcher from "../../../views/FeedsPage/transformerFunctions/MarkDownFetcher";

const archiveActions = [
  { value: "NONE", label: "NONE" },
  { value: "DELETE", label: "DELETE" },
  { value: "MOVE", label: "MOVE" },
];

const CreateNewButton = styled.div`
  margin-bottom: 0.5em;
  display: block;
  font-size: 0.8em;
  font-family: "Source Sans Pro Semibold";
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
  color: ${(props) => props.theme.onSecondarySurface};
`;

const EgressName = ({ props, editEgressPath }) => {
  //Show Helper
  const [showHelper, setShowHelper] = useState(false);

  const egressIndex = props?.values?.egressInfo?.findIndex((egress) => {
    return egress?.egressType === editEgressPath;
  });

  const setHelper = (helperName) => {
    setShowHelper(helperName);
  };

  useEffect(() => {
    const hasPathOrContainer =
      props?.values?.egressInfo?.[egressIndex]?.characterSeparatedFileInfo
        ?.directoryPathToFile ||
      props?.values?.egressInfo?.[egressIndex]?.characterSeparatedFileInfo
        ?.containerName;

    if (
      !props?.values?.egressInfo?.[egressIndex]?.characterSeparatedFileInfo
        ?.fileNameWithWildcard &&
      hasPathOrContainer
    ) {
      props.setErrors({
        egressInfo: {
          [egressIndex]: {
            // dynamically set the index
            characterSeparatedFileInfo: {
              fileNameWithWildcard: "File Name Required",
            },
          },
        },
      });
    }
  }, [props]);

  return (
    <div>
      {showHelper ? (
        <Modal hide={() => setShowHelper(false)}>
          <div
            style={{
              padding: "1rem",
              background: "#fbfbfb",
              marginBottom: "1rem",
            }}
          >
            <MarkdownFetcher
              hideClose
              MdFile={showHelper}
              close={setShowHelper}
            />
          </div>
        </Modal>
      ) : null}

      {props?.values?.egressInfo?.[egressIndex]?.enabled ? (
        <FormControl style={{ marginTop: "1rem" }}>
          <Label>
            File Name With Wildcard, e.g. FILE_NAME_*.csv{" "}
            <MdOutlineHelp
              onClick={() => setHelper("WildcardEgress")}
              style={{
                marginLeft: ".5rem",
                fontSize: "1.2rem",
                cursor: "pointer",
              }}
              title="How do I use the wildcard?"
            />
          </Label>

          <StyledField
            name={`egressInfo[${egressIndex}].characterSeparatedFileInfo.fileNameWithWildcard`}
            type="text"
            placeholder="File Name With Wildcard"
          />
          {props?.errors?.egressInfo?.[egressIndex]?.characterSeparatedFileInfo
            ?.fileNameWithWildcard ? (
            <div style={{ opacity: ".5", color: "red", padding: "1rem" }}>
              File Name Required
            </div>
          ) : null}
        </FormControl>
      ) : null}
    </div>
  );
};

const RemoteInfoForm = ({ apiData, getUpdatedSource }) => {
  const [editEgressPath, setEditEgressPath] = useState(false);
  const [editIngressPath, setEditIngressPath] = useState(false);
  //Show Helper
  const [showHelper, setShowHelper] = useState(false);

  const setHelper = (helperName) => {
    setShowHelper(helperName);
  };

  //remove rules state
  const updateMetaApi = useGraphApi();

  //check for ingress info
  let ingressInfo = apiData?.ingressInfo
    ? Object.assign({}, apiData.ingressInfo)
    : null;

  if (ingressInfo?.archiveAction) {
    ingressInfo.archiveAction = archiveActions.find(
      (aa) => aa.value === ingressInfo.archiveAction
    );
  }

  function handleEgressDataMod(egress) {
    let modEgress = { ...egress };

    if (modEgress?.archiveAction) {
      modEgress.archiveAction = archiveActions.find(
        (aa) => aa.value === modEgress.archiveAction
      );
    }

    if (modEgress?.details) {
      if (typeof modEgress.details === "string") {
        try {
          modEgress.details = JSON.parse(modEgress.details);
        } catch (error) {
          console.error("Invalid JSON string in details", error);
          return null; // or handle the error as needed
        }
      }
      modEgress.id = modEgress?.id ?? modEgress?.id;
      modEgress.egressType = modEgress?.type ?? modEgress?.egressType;
      modEgress.connectionId =
        modEgress?.targetConnectionId ?? modEgress?.connectionId;
      modEgress.characterSeparatedFileInfo = {
        containerName: modEgress?.details?.ContainerName,
        directoryPathToFile: modEgress?.details?.DirectoryPathToFile,
        fileNameWithWildcard: modEgress?.details?.FileNameWithWildcard,
        hasHeaderRow: modEgress?.details?.HasHeaderRow,
        valueSeparator: modEgress?.details?.ValueSeparator,
      };
    }

    return modEgress;
  }

  let egressInfo = apiData?.egressInfo
    ? apiData.egressInfo.map((egress) => {
        return handleEgressDataMod(egress);
      })
    : [];

  //callback
  const updateMeta = (values) => {
    const dataSourceId = (apiData && apiData.id) || null;
    let ingressInfo = null;
    let egressInfo = null;
    if (values.ingressInfo) {
      const { targetConnection, sourceId, id, ...cleanIngress } =
        values.ingressInfo;
      ingressInfo = values.ingressInfo ? Object.assign(cleanIngress) : null;

      ingressInfo.archiveAction = ingressInfo?.archiveAction?.value;

      if (ingressInfo?.detailsType === "CHARACTER_SEPARATED_FILE") {
        delete ingressInfo.fileIngressDetails;
        ingressInfo.fileIngressDetails = null;
        ingressInfo.characterSeparatedFileInfo =
          ingressInfo.characterFileIngressDetails;
        delete ingressInfo.characterFileIngressDetails;
        ingressInfo.ingressDetailsType = ingressInfo?.detailsType;
        delete ingressInfo.detailsType;
        delete ingressInfo.characterSeparatedFileInfo?.createFullPath;
      } else if (
        ingressInfo?.detailsType === "EXCEL_FILE" ||
        ingressInfo?.detailsType === "XML_FILE"
      ) {
        ingressInfo.fileIngressDetails = ingressInfo.fileIngressDetails;
        ingressInfo.characterSeparatedFileInfo = null;
        delete ingressInfo.characterFileIngressDetails;
        ingressInfo.ingressDetailsType = ingressInfo?.detailsType;
        delete ingressInfo.detailsType;
        delete ingressInfo.fileIngressDetails?.createFullPath;
      }
      delete ingressInfo.details;
    }

    if (values.egressInfo && values.egressInfo.length > 0) {
      egressInfo = values.egressInfo
        .map((egress) => {
          // Destructure to exclude certain properties and capture the rest in `cleanEgress`
          const { targetConnection, sourceId, ...cleanEgress } = egress;

          // Initialize a new object from `cleanEgress`
          let newEgress = { ...cleanEgress };

          const hasPathOrContainer =
            newEgress.characterSeparatedFileInfo?.directoryPathToFile ||
            newEgress.characterSeparatedFileInfo?.containerName;

          if (hasPathOrContainer) {
            // Set specific properties when conditions are met
            newEgress.egressDetailsType = "CHARACTER_SEPARATED_FILE";
            newEgress.archiveAction = "NONE";
            newEgress.characterSeparatedFileInfo.valueSeparator = ",";
            newEgress.characterSeparatedFileInfo.directoryPathToFile =
              newEgress.characterSeparatedFileInfo.directoryPathToFile ?? "";

            // Remove specific fields
            delete newEgress.fileIngressDetails;
            delete newEgress.details;
            delete newEgress.enabled;
            delete newEgress.type;
            delete newEgress.targetConnectionId;
          } else {
            // If conditions are not met, exclude this egress from the output
            return null;
          }

          return newEgress;
        })
        .filter((egress) => egress !== null); // Filter out null entries to clean up the array
    } else {
      egressInfo = [];
    }

    const variables = {
      id: dataSourceId,
      egressInfo: egressInfo,
      ingressInfo: ingressInfo,
    };

    updateMetaApi.doFetch(setDataSourceRemoteInformation, variables);
  };

  useEffect(() => {
    if (updateMetaApi.apiData) {
      getUpdatedSource();
    }
  }, [updateMetaApi.apiData, getUpdatedSource]);

  return (
    <>
      <h3>Connection Details</h3>

      <Formik
        initialValues={{
          egressInfo: egressInfo,
          ingressInfo: ingressInfo,
        }}
        enableReinitialize
        validateOnMount={true}
        validationSchema={Yup.object().shape({
          ingressInfo: Yup.object()
            .shape({
              detailsType: Yup.mixed().required("Required"),
              archiveAction: Yup.mixed().required("Required"),
              characterFileIngressDetails: Yup.mixed().when("detailsType", {
                is: (val) => val === "CHARACTER_SEPARATED_FILE",
                then: Yup.object().shape({
                  valueSeparator: Yup.string().required("Required"),
                  hasHeaderRow: Yup.boolean(),
                  fileNameWithWildcard: Yup.string().required("Required"),
                }),
                otherwise: Yup.mixed().nullable(),
              }),
              fileIngressDetails: Yup.mixed().when("detailsType", {
                is: (val) =>
                  val !== "CHARACTER_SEPARATED_FILE" && val !== "XML_FILE",
                then: Yup.object().shape({
                  hasHeaderRow: Yup.boolean(),
                  fileNameWithWildcard: Yup.string().required("Required"),
                }),
                otherwise: Yup.mixed().nullable(),
              }),
            })
            .nullable(),
        })}
        onSubmit={(values, actions) => {
          updateMeta(values);
        }}
      >
        {(props) => {
          const setEgress = (e, connectionId, editEgressPath) => {
            const egressInfo = props.values.egressInfo || [];

            // Find the index of the entry with the same `egressType` or determine the position to insert new entry
            let egressIndex = egressInfo.findIndex(
              (egress) => egress.egressType === editEgressPath
            );

            // Update existing entry or add a new entry
            if (egressIndex === -1) {
              egressIndex = egressInfo.length;
              const newEgress = {
                egressType: editEgressPath,
                characterSeparatedFileInfo: {
                  directoryPathToFile: e?.path || "/",
                  containerName: e?.container || "/",
                  hasHeaderRow: true,
                  fileNameWithWildcard: "", // Initialize with empty string
                },
                connectionId: connectionId,
                enabled: true,
              };
              // Using setFieldValue to add the new egress entry
              props.setFieldValue(`egressInfo`, [...egressInfo, newEgress]);
            } else {
              const basePath = `egressInfo[${egressIndex}]`;
              props.setFieldValue(
                `${basePath}.characterSeparatedFileInfo.directoryPathToFile`,
                e?.path || "/"
              );
              props.setFieldValue(
                `${basePath}.characterSeparatedFileInfo.containerName`,
                e?.container || "/"
              );
              props.setFieldValue(
                `${basePath}.characterSeparatedFileInfo.hasHeaderRow`,
                true
              );
              props.setFieldValue(`${basePath}.connectionId`, connectionId);
              props.setFieldValue(`${basePath}.enabled`, true);

              // Ensure fileNameWithWildcard is initialized if missing
              if (
                !props.values.egressInfo[egressIndex].characterSeparatedFileInfo
                  .fileNameWithWildcard
              ) {
                props.setFieldValue(
                  `${basePath}.characterSeparatedFileInfo.fileNameWithWildcard`,
                  ""
                );
              }
            }

            // Stop editing the egress path
            setEditEgressPath(false);
          };

          const setIngress = (e, connectionId) => {
            let propPath =
              (ingressInfo &&
                (ingressInfo?.detailsType === "XML_FILE" ||
                  ingressInfo?.detailsType)) === "EXCEL_FILE"
                ? "fileIngressDetails"
                : "characterFileIngressDetails";

            props.setFieldValue(
              `ingressInfo.${propPath}.directoryPathToFile`,
              e?.path ? e?.path : "/"
            );

            props.setFieldValue(
              `ingressInfo.${propPath}.containerName`,
              e?.container ? e?.container : "/"
            );

            setEditIngressPath(false);
          };

          const setErrorsClean = () => {
            props.setFieldError(
              `egressInfo.characterSeparatedFileInfo.fileNameWithWildcard`,
              null
            );
          };

          const clearEgress = (typeOfEgress) => {
            const currentEgress = props?.values?.egressInfo?.filter(
              (ei) => ei?.egressType !== typeOfEgress
            );

            props.setFieldValue(`egressInfo`, currentEgress);
            setErrorsClean();
          };

          const isGenerated = apiData?.tagInstances?.some(
            (tagInstance) => tagInstance.tagId === -7
          );

          const isXML = props?.ingressInfo?.detailsType === "XML_FILE";

          const generalEgress = props?.values?.egressInfo?.find(
            (e) => e?.egressType === "GENERAL"
          );
          const transferEgress = props?.values?.egressInfo?.find(
            (e) => e?.egressType === "SERVICER_TRANSFER_OUTPUT"
          );

          const generalEgresshasPathOrContainer =
            generalEgress?.characterSeparatedFileInfo?.directoryPathToFile ||
            generalEgress?.characterSeparatedFileInfo?.containerName;

          const transferEgresshasPathOrContainer =
            transferEgress?.characterSeparatedFileInfo?.directoryPathToFile ||
            transferEgress?.characterSeparatedFileInfo?.containerName;

          return (
            <form
              onSubmit={props.handleSubmit}
              data-testid="source-details-form"
            >
              {showHelper ? (
                <Modal hide={() => setShowHelper(false)}>
                  <div
                    style={{
                      padding: "1rem",
                      background: "#fbfbfb",
                      marginBottom: "1rem",
                    }}
                  >
                    <MarkdownFetcher
                      hideClose
                      MdFile={showHelper}
                      close={setShowHelper}
                    />
                  </div>
                </Modal>
              ) : null}
              <h3>
                Source Location{" "}
                <MdOutlineHelp
                  onClick={() => setHelper("SourceLocation")}
                  style={{
                    marginLeft: ".5rem",
                    fontSize: "1.2rem",
                    cursor: "pointer",
                  }}
                  title="What is Source Location?"
                />
              </h3>
              <h4>
                Connection:{" "}
                <StyledLink
                  style={{ marginRight: "1rem" }}
                  to={`/connections/${apiData?.connection?.id}/sources`}
                  title="Connection"
                >
                  {apiData?.connection?.name}
                </StyledLink>
              </h4>

              {apiData?.ingressInfo ? (
                <>
                  <h4>Source Location: </h4>

                  <div
                    style={{
                      justifyContent: "flex-start",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CreateNewButton
                      style={{ marginRight: "1rem" }}
                      onClick={() => setEditIngressPath(true)}
                    >
                      Select Ingress Path
                    </CreateNewButton>
                  </div>

                  {editIngressPath ? (
                    <Modal
                      title="Edit Ingress Path"
                      hide={() => setEditIngressPath(false)}
                    >
                      <SelectEgressForm
                        isIngress={true}
                        connectionId={apiData?.connection?.id}
                        selectPath={(e, connectionId) => {
                          setIngress(e, connectionId);
                        }}
                      />
                    </Modal>
                  ) : null}

                  {props?.values?.ingressInfo?.characterFileIngressDetails
                    ?.containerName &&
                  props?.values?.ingressInfo?.characterFileIngressDetails
                    ?.containerName !== "/"
                    ? props?.values?.ingressInfo?.characterFileIngressDetails
                        ?.containerName + "/"
                    : isGenerated
                    ? "N/A - This source was generated within the platform."
                    : null}

                  {props?.values?.ingressInfo?.characterFileIngressDetails
                    ?.directoryPathToFile
                    ? props?.values?.ingressInfo?.characterFileIngressDetails
                        ?.directoryPathToFile === "/"
                      ? null
                      : props?.values?.ingressInfo?.characterFileIngressDetails
                          ?.directoryPathToFile
                    : null}

                  {props?.values?.ingressInfo?.characterFileIngressDetails
                    ?.fileNameWithWildcard
                    ? props?.values?.ingressInfo?.characterFileIngressDetails
                        ?.fileNameWithWildcard
                    : null}

                  {props?.values?.ingressInfo?.fileIngressDetails
                    ?.containerName &&
                  props?.values?.ingressInfo?.fileIngressDetails
                    ?.containerName !== "/"
                    ? props?.values?.ingressInfo?.fileIngressDetails
                        ?.containerName + "/"
                    : isGenerated
                    ? "N/A - This source was generated within the platform."
                    : null}

                  {props?.values?.ingressInfo?.fileIngressDetails
                    ?.directoryPathToFile
                    ? props?.values?.ingressInfo?.fileIngressDetails
                        ?.directoryPathToFile === "/"
                      ? null
                      : props?.values?.ingressInfo?.fileIngressDetails
                          ?.directoryPathToFile
                    : null}

                  {props?.values?.ingressInfo?.fileIngressDetails
                    ?.fileNameWithWildcard
                    ? props?.values?.ingressInfo?.fileIngressDetails
                        ?.fileNameWithWildcard
                    : null}
                </>
              ) : (
                <h4>
                  Source Location:{" "}
                  {apiData?.ingressInfo?.characterFileIngressDetails
                    ?.containerName
                    ? apiData?.ingressInfo?.characterFileIngressDetails
                        ?.containerName + "/"
                    : isGenerated
                    ? "N/A - This source was generated within the platform."
                    : null}
                  {apiData?.ingressInfo?.characterFileIngressDetails
                    ?.createFullPath
                    ? apiData?.ingressInfo?.characterFileIngressDetails
                        ?.createFullPath
                    : null}
                  {apiData?.ingressInfo?.fileIngressDetails?.containerName
                    ? apiData?.ingressInfo?.fileIngressDetails?.containerName +
                      "/"
                    : null}
                  {apiData?.ingressInfo?.fileIngressDetails?.createFullPath
                    ? apiData?.ingressInfo?.fileIngressDetails?.createFullPath
                    : null}
                </h4>
              )}

              {ingressInfo &&
              ingressInfo?.detailsType === "CHARACTER_SEPARATED_FILE" ? (
                <div style={{ marginTop: "1rem" }}>
                  <FormControl>
                    <label>
                      Has Header Row
                      <Field
                        type="checkbox"
                        checked={
                          props.values.ingressInfo.characterFileIngressDetails
                            .hasHeaderRow
                        }
                        name={`ingressInfo.characterFileIngressDetails.hasHeaderRow`}
                      />
                    </label>
                  </FormControl>

                  <FormControl>
                    <Label>
                      Value Separator
                      <MdOutlineHelp
                        onClick={() => setHelper("ValueSeparator")}
                        style={{
                          marginLeft: ".5rem",
                          fontSize: "1.2rem",
                          cursor: "pointer",
                        }}
                        title="What are common value separators?"
                      />
                    </Label>
                    <StyledField
                      name={`ingressInfo.characterFileIngressDetails.valueSeparator`}
                      type="text"
                      placeholder="Value Separator Character"
                    />
                    <ErrorMessage
                      name={`ingressInfo.characterFileIngressDetails.valueSeparator`}
                    />
                  </FormControl>

                  <FormControl>
                    <Label>
                      File Name With Wildcard, e.g. FILE_NAME_*.csv{" "}
                      <MdOutlineHelp
                        onClick={() => setHelper("WildcardIngress")}
                        style={{
                          marginLeft: ".5rem",
                          fontSize: "1.2rem",
                          cursor: "pointer",
                        }}
                        title="How do I use the wildcard?"
                      />
                    </Label>

                    <StyledField
                      name={`ingressInfo.characterFileIngressDetails.fileNameWithWildcard`}
                      type="text"
                      placeholder="File Name With Wildcard"
                    />
                    <ErrorMessage
                      name={`ingressInfo.characterFileIngressDetails.fileNameWithWildcard`}
                    />
                  </FormControl>

                  <FormControl>
                    <Label>Archive Action</Label>

                    <StyledSelect
                      className={`react-select-container`}
                      classNamePrefix={`react-select`}
                      name={`ingressInfo.archiveAction`}
                      id={`archiveAction`}
                      inputId={`archiveAction-archiveActionSelect-input`}
                      instanceId={`archiveAction-archiveActionSelect-instance`}
                      label="Archive Action"
                      options={archiveActions.map((aa) => {
                        return {
                          value: aa.value,
                          label: aa.label,
                          isDisabled:
                            aa.value === "NONE" &&
                            apiData.activeMonitoring === true,
                        };
                      })}
                      placeholder={`Action`}
                      value={props.values.ingressInfo.archiveAction}
                      menuPlacement="auto"
                      menuPortalTarget={document.body}
                      onChange={(e) =>
                        props.setFieldValue(`ingressInfo.archiveAction`, e)
                      }
                    />
                  </FormControl>
                  <FormControl>
                    <label>
                      Ignore Quotes
                      <MdOutlineHelp
                        onClick={() => setHelper("QuotesIgnore")}
                        style={{
                          marginLeft: ".5rem",
                          fontSize: "1.2rem",
                          cursor: "pointer",
                        }}
                        title="What does ignoring quotes do?"
                      />
                      <Field
                        type="checkbox"
                        name={`ingressInfo.characterFileIngressDetails.ignoreQuotes`}
                      />
                    </label>
                  </FormControl>
                  <FormControl>
                    <Label>
                      Quote Character
                      <MdOutlineHelp
                        onClick={() => setHelper("QuotesIngress")}
                        style={{
                          marginLeft: ".5rem",
                          fontSize: "1.2rem",
                          cursor: "pointer",
                        }}
                        title="What does specifying the quote character do?"
                      />
                    </Label>
                    <StyledField
                      name={`ingressInfo.characterFileIngressDetails.quoteCharacter`}
                      type="text"
                      placeholder="Quote Character"
                    />
                    <ErrorMessage
                      name={`ingressInfo.characterFileIngressDetails.quoteCharacter`}
                    />
                  </FormControl>
                </div>
              ) : null}

              {apiData?.etlParameterName && (
                <FormControl style={{ marginTop: "1rem" }}>
                  <Label>ETL Parameter Name:&nbsp;</Label>
                  <Copy>{apiData?.etlParameterName}</Copy>
                </FormControl>
              )}

              {apiData?.overrideInitializingProcess?.initializingProcess ===
                "RULES_ENGINE_PLATFORM2" && (
                <FormControl>
                  <label>
                    Copy Internal File to Ingress Location
                    <MdOutlineHelp
                      onClick={() => setHelper("InternalToIngress")}
                      style={{
                        marginLeft: ".5rem",
                        fontSize: "1.2rem",
                        cursor: "pointer",
                      }}
                      title="What does copy internal file to ingress location do?"
                    />
                    <Field
                      type="checkbox"
                      name={`ingressInfo.copyInternalFileToIngress`}
                    />
                  </label>
                </FormControl>
              )}

              {ingressInfo &&
              ingressInfo?.detailsType === "EXCEL_FILE" &&
              !isXML ? (
                // {ingressInfo && ingressInfo?.detailsType === "EXCEL_FILE" ? (
                <>
                  <FormControl>
                    <label>
                      Has Header Row
                      <Field
                        type="checkbox"
                        checked={
                          props.values.ingressInfo.fileIngressDetails
                            .hasHeaderRow
                        }
                        name={`ingressInfo.fileIngressDetails.hasHeaderRow`}
                      />
                    </label>
                  </FormControl>

                  <FormControl>
                    <StyledField
                      name={`ingressInfo.fileIngressDetails.fileNameWithWildcard`}
                      type="text"
                      placeholder="File Name With Wildcard"
                      label="File Name With Wildcard, e.g. FILE_NAME_*.csv"
                    />
                    <ErrorMessage
                      name={`ingressInfo.fileIngressDetails.fileNameWithWildcard`}
                    />
                  </FormControl>

                  <FormControl>
                    <Label>Archive Action</Label>

                    <StyledSelect
                      className={`react-select-container`}
                      classNamePrefix={`react-select`}
                      name={`ingressInfo.archiveAction`}
                      id={`archiveAction`}
                      inputId={`archiveAction-archiveActionSelect-input`}
                      instanceId={`archiveAction-archiveActionSelect-instance`}
                      label="Archive Action"
                      menuPortalTarget={document.body}
                      options={archiveActions.map((aa) => {
                        return {
                          value: aa.value,
                          label: aa.label,
                          isDisabled:
                            aa.value === "NONE" &&
                            apiData.activeMonitoring === true,
                        };
                      })}
                      placeholder={`Action`}
                      value={props.values.ingressInfo.archiveAction}
                      menuPlacement="auto"
                      onChange={(e) =>
                        props.setFieldValue(`ingressInfo.archiveAction`, e)
                      }
                    />
                  </FormControl>
                </>
              ) : null}
              {ingressInfo && ingressInfo?.detailsType === "XML_FILE" ? (
                <>
                  {/* Adding Row Element Name for XML Files its the name of tag <RowInfo> e.g</RowInfo> */}
                  <FormControl>
                    <StyledField
                      name={`ingressInfo.fileIngressDetails.rowElement`}
                      type="text"
                      placeholder="Row Element Name"
                      label="Row Element Name"
                    />
                    <ErrorMessage
                      name={`ingressInfo.fileIngressDetails.rowElement`}
                    />
                  </FormControl>

                  <FormControl>
                    <Label>Archive Action</Label>

                    <StyledSelect
                      className={`react-select-container`}
                      classNamePrefix={`react-select`}
                      name={`ingressInfo.archiveAction`}
                      id={`archiveAction`}
                      inputId={`archiveAction-archiveActionSelect-input`}
                      instanceId={`archiveAction-archiveActionSelect-instance`}
                      label="Archive Action"
                      menuPortalTarget={document.body}
                      options={archiveActions.map((aa) => {
                        return {
                          value: aa.value,
                          label: aa.label,
                          isDisabled:
                            aa.value === "NONE" &&
                            apiData.activeMonitoring === true,
                        };
                      })}
                      placeholder={`Action`}
                      value={props.values.ingressInfo.archiveAction}
                      menuPlacement="auto"
                      onChange={(e) =>
                        props.setFieldValue(`ingressInfo.archiveAction`, e)
                      }
                    />
                  </FormControl>
                </>
              ) : null}
              <h3>
                Export Path{" "}
                <MdOutlineHelp
                  onClick={() => setHelper("ExportPath")}
                  style={{
                    marginLeft: ".5rem",
                    fontSize: "1.2rem",
                    cursor: "pointer",
                  }}
                  title="What is Export Path?"
                />
              </h3>

              {/* GENERAL Export Path */}
              <div style={{ marginBottom: "1rem" }}>
                <h4>General Export Path</h4>
                <p>
                  Used for selecting where you want any of your exported files,
                  including custom reports located when processed.
                </p>
                <div
                  style={{
                    justifyContent: "flex-start",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CreateNewButton
                    style={{ marginRight: "1rem" }}
                    onClick={() => setEditEgressPath("GENERAL")}
                  >
                    Select Export Path
                  </CreateNewButton>
                  <CreateNewButton
                    style={{ color: "red", marginLeft: "auto" }}
                    onClick={() => clearEgress("GENERAL")}
                  >
                    Clear Export Path
                  </CreateNewButton>
                </div>
                <div>
                  <strong>Export Path Container: {"  "}</strong>
                  {generalEgress?.enabled &&
                  generalEgress?.characterSeparatedFileInfo?.containerName
                    ? generalEgress?.characterSeparatedFileInfo?.containerName
                    : null}
                </div>
                <div>
                  <strong>Export Path: {"  "}</strong>
                  {generalEgress?.enabled &&
                  generalEgress?.characterSeparatedFileInfo?.directoryPathToFile
                    ? generalEgress?.characterSeparatedFileInfo
                        ?.directoryPathToFile
                    : null}
                </div>
                {editEgressPath === "GENERAL" ? (
                  <Modal
                    title="Edit Export Path"
                    hide={() => setEditEgressPath(false)}
                  >
                    <SelectEgressForm
                      selectPath={(e, connectionId) => {
                        setEgress(e, connectionId, editEgressPath);
                      }}
                    />
                  </Modal>
                ) : null}
                {generalEgresshasPathOrContainer ? (
                  <EgressName props={props} editEgressPath={"GENERAL"} />
                ) : null}
              </div>

              {/* SERVICER_TRANSFER_OUTPUT Export Path */}
              <div
                style={{
                  marginBottom: "1rem",
                  paddingTop: "1rem",
                  borderTop: "solid 1px #ccc",
                }}
              >
                <h4>SERVICER TRANSFER OUTPUT</h4>
                <p>
                  Used for isolating where you want your transfer output files
                  to be located when processed.
                </p>
                <div
                  style={{
                    justifyContent: "flex-start",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CreateNewButton
                    style={{ marginRight: "1rem" }}
                    onClick={() =>
                      setEditEgressPath("SERVICER_TRANSFER_OUTPUT")
                    }
                  >
                    Select Export Path
                  </CreateNewButton>
                  <CreateNewButton
                    style={{ color: "red", marginLeft: "auto" }}
                    onClick={() => clearEgress("SERVICER_TRANSFER_OUTPUT")}
                  >
                    Clear Export Path
                  </CreateNewButton>
                </div>
                <div>
                  <strong>Export Path Container: {"  "}</strong>
                  {transferEgress?.enabled &&
                  transferEgress?.characterSeparatedFileInfo?.containerName
                    ? transferEgress?.characterSeparatedFileInfo?.containerName
                    : null}
                </div>
                <div>
                  <strong>Export Path: {"  "}</strong>
                  {transferEgress?.enabled &&
                  transferEgress?.characterSeparatedFileInfo
                    ?.directoryPathToFile
                    ? transferEgress?.characterSeparatedFileInfo
                        ?.directoryPathToFile
                    : null}
                </div>
                {editEgressPath === "SERVICER_TRANSFER_OUTPUT" ? (
                  <Modal
                    title="Edit Export Path"
                    hide={() => setEditEgressPath(false)}
                  >
                    <SelectEgressForm
                      selectPath={(e, connectionId) => {
                        setEgress(e, connectionId, editEgressPath);
                      }}
                    />
                  </Modal>
                ) : null}
                {transferEgresshasPathOrContainer ? (
                  <EgressName
                    props={props}
                    editEgressPath={"SERVICER_TRANSFER_OUTPUT"}
                  />
                ) : null}
              </div>

              {updateMetaApi.errors ? (
                <ErrorMessages errors={updateMetaApi.errors} />
              ) : null}
              <FormActions>
                <Button
                  type="submit"
                  disabled={updateMetaApi.loading || !props.isValid}
                >
                  {updateMetaApi.loading ? (
                    <Spinner />
                  ) : (
                    "Update Connection Details"
                  )}
                </Button>
              </FormActions>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default RemoteInfoForm;
