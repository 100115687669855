import React, { useCallback, useState, useEffect, useContext } from "react";
import { useApi } from "../../api/useApi";
import { getAllServicerTransferFeeds } from "../../api/serviceTransferQueries";
import {
  startServicerTransferFeed,
  setServicerTransferFeedEnabledFlag,
  startAllServicerTransferFeeds,
  endFeedRefreshSummaryQuery,
} from "../../api/serviceTransferMutations";
import PagedTable from "../../components/Table/PagedTable";
import { MdArchive, MdError, MdHistory } from "react-icons/md";
import StyledLink from "../../components/StyledLink";
import {
  newFeed,
  manageOutputSource,
  manageInputSource,
  dataSource,
} from "../../common/paths";
import TableButton from "../../components/Button/TableButton";
import { Link } from "react-router-dom";
import CTAMessage from "../../components/Help/CTAMessage";
import Notification from "../../components/Notification";
import { AuthContext } from "../../contexts/AuthContext";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import Spinner from "../../components/Loaders/Spinner";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import columnSort from "../../components/Table/helpers/columnSort";
import ColumnFilter from "../../components/Table/ColumnFilter";
import getFilteredObject from "../../components/Table/helpers/columnFilter";
import useFeedExportNotification from "../../Hooks/useFeedExportNotification";
import useFeedPercentNotifications from "../../Hooks/useFeedPercentNotification";
import FilterByTags from "../../components/Tags/FilterByTags";
import DataSourceProcessing from "../../components/Widgets/DataSourceWidgets/DataSourceProcessing";
import Secondary from "../../components/Button/Secondary";
import { ToastContainer, toast } from "react-toastify";
import { NotificationLoading } from "../../components/Notification/NotificationLoading";

const getFeedStatus = (isDone, activeExport) => {
  let status = "";
  if (isDone) {
    if (activeExport?.ValidationOnly) {
        status = "Finished Validation.";
    } else if (activeExport?.ResultState === -2) {
        status = "Cancelled Transfer.";
    } else {
      status = "Finished Transfer.";
    }
  } else if (
    activeExport?.PercentageComplete &&
    activeExport?.PercentageComplete > 0 &&
    activeExport?.PercentageComplete < 100
  ) {
    status = `Processing ${activeExport?.PercentageComplete}%`;
  } else if (activeExport?.ReadStatus && activeExport?.ReadStatus !== "") {
    status = activeExport?.ReadStatus.split(" rsId")[0];
  } else if (!activeExport?.FileUrls?.length) {
    status = "Initializing Request";
  } else {
    return null;
  }

  return status;
};

const ImportSource = ({ source }) => {
  return (
    <div style={{ display: "block" }}>
      <StyledLink to={manageInputSource(source?.id)}>{source?.name}</StyledLink>

      <DataSourceProcessing sourceId={source?.id} inline />
    </div>
  );
};

const SourceTable = ({ archive }) => {
  const [showExport, setShowExport] = useState(false);
  const { user } = useContext(AuthContext);
  //Init Data Fetch
  const [{ loading, errors, data: apiData }, doFetch] = useApi();

  //HAndle Archive state
  const [showConfirm, setShowConfirm] = useState(false);
  const [feedToDelete, setFeedToDelete] = useState(null);
  const [currentView, setCurrentView] = useState(null);
  const [filter, setFilter] = useState(null);

  // Manage Percent Complete List
  const { feedExportNotification, setFeedExportNotification } =
    useFeedExportNotification();
  const { feedPercentNotification, setFeedPercentNotification } =
    useFeedPercentNotifications();

  // keep track of active in process report building
  const [activeReports, setActiveReports] = useState([]);

  // update reports
  useEffect(() => {
    if (feedPercentNotification) {
      setActiveReports((prev) => {
        let cloneExisting = [...prev];
        if (
          !cloneExisting.find(
            (ce) => ce?.SourceId === feedPercentNotification?.payload?.SourceId
          )
        ) {
          cloneExisting.push(feedPercentNotification?.payload);
          setFeedPercentNotification(null);

          return cloneExisting;
        } else {
          const currentIndex = cloneExisting
            .map((e) => e.SourceId)
            .indexOf(feedPercentNotification?.payload?.SourceId);

          cloneExisting[currentIndex] = feedPercentNotification?.payload;

          setFeedPercentNotification(null);

          return cloneExisting;
        }
      });
    }
    if (feedExportNotification) {
      setActiveReports((prev) => {
        let cloneExisting = [...prev];
        if (
          !cloneExisting.find(
            (ce) => ce?.SourceId === feedExportNotification?.payload?.SourceId
          )
        ) {
          cloneExisting.push(feedExportNotification?.payload);
          setFeedExportNotification(null);
          return cloneExisting;
        } else {
          const currentIndex = cloneExisting
            .map((e) => e.SourceId)
            .indexOf(feedExportNotification?.payload?.SourceId);

          cloneExisting[currentIndex] = feedExportNotification?.payload;
          setFeedExportNotification(null);
          return cloneExisting;
        }
      });
    }
  }, [
    feedPercentNotification,
    setActiveReports,
    feedExportNotification,
    setFeedExportNotification,
    setFeedPercentNotification,
  ]);

  //Filter Tags
  const [tagFilter, setTagFilter] = useState([]);
  const [exactMatchTagFilter, setExactMatchTagFilter] = useState(false);
  const [manuallySelected, setManuallySelected] = useState(null);

  //remove api
  const [
    { loading: removalLoading, data: removalData, errors: removalErrors },
    remove,
  ] = useApi();

  const toggleSourceEnabled = ({ id, enabled }) => {
    remove({
      query: setServicerTransferFeedEnabledFlag,
      variables: { id: id, enabled: !enabled },
    });
  };

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor, sortBy }) => {
      const sortedObject = columnSort(sortBy);
      const filteredObject = getFilteredObject(filter);

      setCurrentView(cursor);
      doFetch({
        query: getAllServicerTransferFeeds,
        variables: {
          first: pageSize,
          after: cursor,
          tagFilter: {
            tagIds: [...tagFilter?.map((tf) => tf.tagId)],
            exactMatch: exactMatchTagFilter,
          },
          where: {
            enabled: { eq: archive ? false : true },
            ...(filteredObject && {
              ...filteredObject,
            }),
          },
          ...(sortedObject
            ? {
                order: {
                  ...sortedObject,
                },
              }
            : {
                order: {
                  name: "ASC",
                },
              }),
        },
      });
    },
    [doFetch, setCurrentView, archive, filter, tagFilter, exactMatchTagFilter]
  );

  //handle remove rule update
  useEffect(() => {
    if (removalData && !removalErrors?.length) {
      fetchData({ pageSize: 10, cursor: currentView ?? null });
      setShowConfirm(false);
      setFeedToDelete(null);
    }
  }, [removalData, fetchData, currentView, removalErrors]);

  useEffect(() => {
    if (feedToDelete) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [feedToDelete]);

  const [
    { errors: startFeedErrors, data: startFeedExport },
    doStartFeedExport,
  ] = useApi();

  const [
    { errors: cancelFeedErrors, data: cancelFeedExport },
    doCancelFeedExport,
  ] = useApi();

  const [
    {
      loading: startAllFeedLoading,
      errors: startAllFeedErrors,
      data: startAllFeedExport,
    },
    doStartAllFeedExport,
  ] = useApi();

  const resetExport = useCallback(() => {
    setShowExport(false);
  }, [setShowExport]);

  // Start Transfer
  const createCSV = useCallback(
    ({ feedId }) => {
      doStartFeedExport({
        query: startServicerTransferFeed,
        variables: {
          feedId: feedId,
        },
      });
    },
    [doStartFeedExport]
  );

  // End Feed RefreshSummary
  const endFeedRefreshSummary = useCallback(
    ({ feedId }) => {
      doCancelFeedExport({
        query: endFeedRefreshSummaryQuery,
        variables: {
          feedId: feedId,
        },
      });
    },
    [doCancelFeedExport]
  );

  // Start Transfer Validation
  const startValidation = useCallback(
    ({ feedId }) => {
      doStartFeedExport({
        query: startServicerTransferFeed,
        variables: {
          feedId: feedId,
          validationRun: true,
        },
      });
    },
    [doStartFeedExport]
  );

  const startAllFeeds = useCallback(() => {
    doStartAllFeedExport({
      query: startAllServicerTransferFeeds,
    });
  }, [doStartAllFeedExport]);

  useEffect(() => {
    if (startAllFeedExport && !startAllFeedErrors && !startAllFeedLoading) {
      toast.success("All Transformations Running", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [startAllFeedExport, startAllFeedErrors, startAllFeedLoading]);

  useEffect(() => {
    if (startFeedExport) {
      const feed = startFeedExport?.startServicerTransferFeed;

      setShowExport(true);
      setActiveReports((prev) => {
        let cloneExisting = [...prev];

        if (!cloneExisting.find((ce) => ce?.SourceId === feed?.feedId)) {
          cloneExisting.push({
            SourceId: feed?.feedId,
            PercentageComplete: 0,
          });

          return cloneExisting;
        } else {
          const currentIndex = cloneExisting
            .map((e) => e.SourceId)
            .indexOf(feed?.feedId);

          cloneExisting[currentIndex] = {
            SourceId: feed?.feedId,
            PercentageComplete: 0,
          };

          return cloneExisting;
        }
      });
    }
  }, [startFeedExport, setShowExport]);

  const transfers = apiData?.allServicerTransferFeeds?.edges ?? [];
  const totalCount = apiData?.allServicerTransferFeeds?.totalCount;
  const pageInfo = apiData?.allServicerTransferFeeds?.pageInfo;

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      filter: true,
      accessor: (d) => d?.node?.name,
      Cell: ({ row: { original } }) => {
        return (
          <StyledLink to={`/feeds/${original?.node?.id}`}>
            {original?.node?.name}
          </StyledLink>
        );
      },
    },
    {
      Header: () => {
        return (
          <div style={{ display: "flex", alignContent: "center" }}>
            <div style={{ fontSize: "0.875rem" }}>Input Sources</div>
            <div
              style={{
                display: "flex",
                marginLeft: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  minWidth: "50px",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    height: "1px",
                    backgroundColor: "#ccc",
                  }}
                ></div>
                <div
                  style={{
                    flex: 0,
                    width: 0,
                    height: 0,
                    borderTop: "5px solid transparent",
                    borderBottom: "5px solid transparent",
                    borderLeft: "10px solid #ccc",
                  }}
                ></div>
              </div>
            </div>
          </div>
        );
      },
      id: "sourceDataSource.name",
      Cell: ({ row: { original } }) => {
        const sources = original?.node?.sourceDataSources;
        return (
          <>
            <div>
              {" "}
              {sources.map((s) => {
                return <ImportSource source={s} />;
              })}
            </div>
          </>
        );
      },
    },
    {
      Header: "Output Source",
      id: "destinationDataSource.name",
      Cell: ({ row: { original } }) => {
        return (
          <StyledLink
            to={manageOutputSource(original?.node?.destinationDataSourceId)}
          >
            {original?.node?.destinationDataSource?.name}
          </StyledLink>
        );
      },
    },
    {
      Header: "Export Source",
      id: "exportSource",
      Cell: ({ row: { original } }) => {
        const exportSource =
          original?.node?.destinationDataSource?.transferEgress?.exportSource;
        return exportSource?.enabled ? (
          <StyledLink to={dataSource(exportSource?.id)}>
            {exportSource?.name}
          </StyledLink>
        ) : null;
      },
    },
    {
      Header: "Export Status",
      id: "exportStatus",
      Cell: ({ row: { original } }) => {
        const feedId = original?.node?.id;
        const activeExport = activeReports.find((r) => r?.SourceId === feedId);
        const isDone = activeExport && activeExport?.PercentageComplete === 100;

        if (activeExport) {
          return (
            <>
              {activeExport?.FileUrls?.length && (
                <>
                  {activeExport?.FileUrls.map((files) => {
                    return (
                      <div>
                        <a
                          href={files?.Url ?? ""}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {files?.Name}
                        </a>
                      </div>
                    );
                  })}
                </>
              )}
              {activeExport?.ErrorMessage && (
                <div style={{ display: "flex", alignItems: "top" }}>
                  <MdError style={{ color: "red", fontSize: "1.3rem" }} />{" "}
                  {activeExport?.ErrorMessage}
                </div>
              )}
              {getFeedStatus(isDone, activeExport)}
              {activeExport?.ExceptionsEncountered && (
                <div style={{ color: "red" }}>
                  Exceptions Count({activeExport?.ExceptionsEncountered})
                </div>
              )}
            </>
          );
        } else {
          return null;
        }
      },
    },
    {
      Header: "Tags",
      id: "tags",
      Cell: ({ row: { original } }) => {
        return (
          <div>
            {original?.node?.tagInstances?.map((ti, i) => {
              const isLast = original?.node?.tagInstances?.length - 1 <= i;
              return (
                <div
                  onClick={() => setManuallySelected([ti])}
                  style={{
                    display: "inline-block",
                    background: "#e6e6e6",
                    padding: "0.2rem",
                    paddingLeft: ".4rem",
                    paddingRight: ".4rem",
                    fontSize: ".8rem",
                    marginRight: isLast ? "" : ".5rem",
                    marginBottom: isLast ? "" : ".5rem",
                    cursor: "pointer",
                  }}
                >
                  {ti?.tag?.name}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      Header: " ",
      id: "actions",
      width: 220,
      sortable: false,
      Cell: ({ totalColumnsWidth, row: { original } }) => {
        const feedId = original?.node?.id;
        const activeExport = activeReports.find((r) => r?.SourceId === feedId);

        const showCancel =
          activeExport?.PercentageComplete &&
          activeExport?.PercentageComplete > 0 &&
          activeExport?.PercentageComplete < 100;

        return (
          <div
            style={{
              textAlign: "right",
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Link to={`/feeds/${original?.node?.id}/history`}>
              <TableButton list="true" type="button">
                <MdHistory />
              </TableButton>
            </Link>

            <Link to={`/feeds/${original?.node?.id}`}>
              <TableButton list="true" type="button">
                Edit
              </TableButton>
            </Link>
            <Link to={`/feeds/${original?.node?.id}/clone`}>
              <TableButton list="true" type="button">
                Clone
              </TableButton>
            </Link>

            <TableButton
              list="true"
              type="button"
              onClick={() =>
                startValidation({
                  feedId: original?.node?.id,
                })
              }
              //   disabled={original?.node?.status !== "Ready"}
            >
              Validate{" "}
            </TableButton>

            {showCancel ? (
              <TableButton
                list="true"
                type="button"
                danger
                onClick={() =>
                  endFeedRefreshSummary({
                    feedId: original?.node?.id,
                  })
                }
                //   disabled={original?.node?.status !== "Ready"}
              >
                Cancel Transfer
              </TableButton>
            ) : (
              <TableButton
                list="true"
                type="button"
                onClick={() =>
                  createCSV({
                    feedId: original?.node?.id,
                  })
                }
                //   disabled={original?.node?.status !== "Ready"}
              >
                Run Transfer{" "}
              </TableButton>
            )}

            {user && user.role >= 2 && (
              <TableButton
                danger={original?.node?.enabled}
                list="true"
                type="button"
                id={`${original?.node?.name}-toggleFeedArchiveReactivate`}
                data-testid={`${original?.node?.name}-toggleFeedArchiveReactivate`}
                title="Archive Toggle"
                bumpdown={true}
                onClick={() =>
                  setFeedToDelete({
                    id: original?.node?.id,
                    enabled: original?.node?.enabled,
                  })
                }
              >
                {original?.node?.enabled ? <MdArchive /> : "Reactivate"}
              </TableButton>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      {transfers?.length ? (
        <div
          style={{
            marginLeft: "auto",
            position: "absolute",
            right: 0,
            marginRight: "1rem",
            marginTop: "-36px",
          }}
        >
          <Secondary
            style={{ padding: "0.5em 1em" }}
            type="button"
            title="Run All Transformations"
            onClick={() => startAllFeeds()}
          >
            {startAllFeedLoading ? <Spinner /> : "Run All"}
          </Secondary>
        </div>
      ) : null}

      {showConfirm ? (
        <Modal
          title={`Confirm Transformation ${
            feedToDelete?.enabled ? "Archival" : "Reactivation"
          }`}
          hide={() => setFeedToDelete(null)}
        >
          <p>
            Are you sure you wish to{" "}
            {feedToDelete?.enabled ? "archive" : "reactivate"} this feed?
          </p>
          <div>
            <Button
              type="button"
              list="true"
              disabled={removalLoading}
              danger
              onClick={() => toggleSourceEnabled(feedToDelete)}
            >
              {removalLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removalLoading}
              onClick={() => {
                setFeedToDelete(null);
              }}
            >
              Cancel
            </Button>
          </div>

          {removalErrors ? <ErrorMessages errors={removalErrors} /> : null}
        </Modal>
      ) : null}

      {showExport ? (
        <NotificationLoading>
          <Notification
            closeCallBack={resetExport}
            text="Your data extract is being prepared; an email will be sent to you when the extract is ready to download."
          />
        </NotificationLoading>
      ) : null}
      {cancelFeedErrors ? <ErrorMessages errors={cancelFeedErrors} /> : null}
      {startFeedErrors ? <ErrorMessages errors={startFeedErrors} /> : null}
      {errors ? <ErrorMessages errors={errors} /> : null}
      {startAllFeedErrors ? (
        <ErrorMessages errors={startAllFeedErrors} />
      ) : null}

      <div style={{ display: "flex", marginTop: "1rem" }}>
        <div style={{ flex: 1, marginRight: "1rem" }}>
          <ColumnFilter column={"name"} setFilter={setFilter} />
        </div>
        <div style={{ flex: 1 }}>
          <FilterByTags
            currentTags={manuallySelected}
            updateTags={setTagFilter}
            tagType="SERVICER_TRANSFER_FEED"
            exactMatch={setExactMatchTagFilter}
          />
        </div>
      </div>

      <PagedTable
        fetchData={fetchData}
        loading={loading}
        pageInfo={pageInfo}
        totalCount={totalCount}
        data={transfers}
        columns={columnsData}
      />

      {!transfers?.length && !loading && !archive ? (
        <CTAMessage
          msg={
            <div style={{ textAlign: "center" }}>
              <p style={{ textAlign: "center" }}>
                No Transformations Available
              </p>
              <p style={{ textAlign: "center" }}>
                <StyledLink to={newFeed()}>Create a Transformation</StyledLink>
              </p>
            </div>
          }
        />
      ) : null}
      <ToastContainer />
    </>
  );
};

export default SourceTable;
