import React, { useEffect, useContext, useCallback } from "react";
import styled from "styled-components/macro";
import DashboardHeader from "../../components/Dashboard/DashboardHeader";
import {
  failureOpportunities,
  totalFailures,
  rowCount,
  constraintFailuresCount,
  qualityScore,
  scoreHistory,
  ruleFailures,
  failuresHistory,
} from "../../api/dataSourceQueries";
import { useApi } from "../../api/useApi";
import { Route, NavLink, useParams, Switch } from "react-router-dom";
import CTAMessage from "../../components/Help/CTAMessage";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import { dataSourceReports } from "../../common/paths";
import FailureDetails from "../DataSourcesPage/Reports/FailureDetails";
import Report from "../DataSourcesPage/Reports/ReportView";
import { format } from "date-fns";
import StyledLink from "../../components/StyledLink";
import SplashLoader from "../../components/Loaders/SplashLoader";
import DataSourceImportance from "../DataSourcesPage/DataSourceImportance";
import IssuesList from "../IssuesPage/IssuesList";
import Card from "../../components/Card";
import ManageSource from "../DataSourcesPage/ManageSource";
import DataProfile from "../../components/Forms/Sources/DataProfile";
import { MdKey } from "react-icons/md";
import { canUseBCA } from "../../common/helpers/auth";
import { AuthContext } from "../../contexts/AuthContext";
import PolicyProfile from "../../components/Forms/Sources/PolicyProfile";

const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  grid-auto-rows: dense;
  grid-gap: 1rem;
  margin-bottom: 1rem;
`;

// Navigation Components
const Tabs = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.surfaceAlt};
  background-color: ${(props) => props.theme.surface};
  position: relative;
  padding: 0.7rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`;

const TabLink = styled(NavLink)`
  font-size: 0.9rem;
  padding: 0.7rem;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  opacity: 0.5;
  display: inline-block;
  color: ${(props) => props.theme.onSurface};
  &.active {
    opacity: 1;
    border-bottom: 2px solid ${(props) => props.theme.onSecondarySurface};
  }
  &:hover {
    opacity: 1;
  }
  text-transform: uppercase;
`;

const TabsLeft = styled.div`
  flex: 1;
`;
const TabsRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  margin-left: auto;
`;

const TimestampWrapper = styled.div`
  font-family: "Source Sans Pro Italic";
`;

const TimestampSeparator = styled.div`
  margin: 0 0.5rem;
  font-size: 1.1rem;
`;

const ImportanceContainer = styled.div`
  display: flex;
  min-height: 3rem;
  align-items: center;
  margin-bottom: 1rem;
`;

const ReportStatus = styled.div`
  background: rgb(0, 153, 255);
  padding: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #efefef;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
`;

const IssueBody = ({ sourceId }) => {
  return <IssuesList filterSource={sourceId} />;
};

const Dashboard = () => {
  let params = useParams();

  const sourceId = Number(params?.sourceId);

  const getLatestReportIdQuery = `
  query($id: Int!) {
     dataSource(id: $id) {
      id
      name
      primaryKeyConfigured
      latestReport{
         refreshSummaryId
       }
     }
   }
`;

  const query = `
  query($id: Int!, $reportId: UUID!) {
     dataSource(id: $id) {
       id
       name
       friendlyName
       columns {
        id
        ordinal
        name
      }
       connection{
        id
        serverType
        name
      }
      priorityLevel
      qualityImpact
      description
      latestRefreshSummaryStatus
      ruleInstancesCount
      primaryKeyConfigured
      reportByIdWithAlert(reportId: $reportId){
        refreshSummaryId
        timestamp
        isGeneratingCachedReport
        refreshSummary {
         createdOn
         batchName
       }
       columnProfiles{
        profileResults {
          columnId
          value
          valueSubType
          valueType
        }
      }
       failureDetails{
        dataSourceMetrics {
          totalFailuresCount
          totalSuccessfulRowsCount
          totalHighPriorityFailuresCount
          totalOtherPriorityFailuresCount
        }
        columnEntries {
          columnId
          details {
            failureCount
            percentFailureCount
            ruleStandardInstancedName
            standardColumnName
          }
          failureCount
          failureCountChange
          name
          previouslyTopOrder
        }
        ruleStandardEntries {
          details {
            columnName
            failureCount
            percentFailureCount
            standardColumnName
          }
          failureCount
          failureCountChange
          name
          previouslyTopOrder
          rulesStandardId
        }
      }
        ${failureOpportunities.fragment}
        ${totalFailures.fragment}
        ${rowCount.fragment}
        ${qualityScore.fragment}
        ${constraintFailuresCount.fragment}
        ${scoreHistory.fragment}
        ${ruleFailures.fragment}
        ${failuresHistory.fragment}
      }
     }
   }
`;

  const [{ loading, errors, data: apiData }, fetch] = useApi();
  const { user } = useContext(AuthContext);
  const [
    { loading: loadingFetch, errors: errorsLatest, data: apiDataLatest },
    fetchLatest,
  ] = useApi();

  const dataSource = apiData?.dataSource ?? apiDataLatest?.dataSource;

  const dataSourceName = apiDataLatest?.dataSource?.name ?? null;
  const isGeneratingCachedReport =
    dataSource?.reportByIdWithAlert?.isGeneratingCachedReport ?? null;
  const priorityLevel = dataSource?.priorityLevel ?? null;
  const description = dataSource?.description ?? null;
  const qualityImpact = dataSource?.qualityImpact ?? null;
  const reportData =
    dataSource?.reportById ??
    dataSource?.reportByIdWithAlert ??
    null;
  const dataSourceBatchName = reportData?.refreshSummary?.batchName ?? null;
  const reportAvailable = apiDataLatest?.dataSource?.latestReport;
  const timestamp =
    dataSource?.reportById?.timestamp ??
    dataSource?.reportByIdWithAlert?.timestamp
      ? format(
          new Date(
            dataSource?.reportById?.timestamp ??
              dataSource?.reportByIdWithAlert?.timestamp
          ).getTime(),
          "MMM dd, HH:mm"
        )
      : null;

  useEffect(() => {
    const variables = {
      id: sourceId,
      dataSourceId: sourceId,
    };

    fetchLatest({ query: getLatestReportIdQuery, variables });
  }, [sourceId, fetchLatest, getLatestReportIdQuery]);

  useEffect(() => {
    if (apiDataLatest?.dataSource?.latestReport?.refreshSummaryId) {
      const variables = {
        id: sourceId,
        reportId: apiDataLatest.dataSource.latestReport.refreshSummaryId,
      };

      fetch({ query: query, variables });
    }
  }, [sourceId, fetch, query, apiDataLatest]);

  const fetchLatestReport = useCallback(() => {
    if (apiDataLatest?.dataSource?.latestReport?.refreshSummaryId) {
      const variables = {
        id: sourceId,
        reportId: apiDataLatest.dataSource.latestReport.refreshSummaryId,
      };

      fetch({ query: query, variables });
    }
  }, [sourceId, fetch, query, apiDataLatest]);

  if (loadingFetch || loading)
    return <SplashLoader text="Loading Data Source" />;

  const reportHasInstances = dataSource?.ruleInstancesCount;

  const isRegularErrors = errors?.length;

  //If there is no report after loading, show failure to load
  // if (!reportHasInstances)
  //   return (
  //     <CTAMessage
  //       msg={
  //         <div>
  //           <p style={{ textAlign: "center" }}>
  //             No Available Report, be sure to have added Rule Standards and
  //             applied them to the Data Source. Also, be sure the source is
  //             enabled.
  //           </p>

  //           {errorsLatest?.length ? (
  //             <CTAMessage
  //               msg={
  //                 <div>
  //                   <ErrorMessages errors={errorsLatest} />
  //                 </div>
  //               }
  //             />
  //           ) : null}

  //           {errors && isRegularErrors ? (
  //             <ErrorMessages errors={errors} />
  //           ) : null}
  //         </div>
  //       }
  //     />
  //   );

  // if (!reportAvailable) return <SplashLoader text="Report In Progress" />;

  //Show Report
  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <ImportanceContainer>
        <DashboardHeader
          batchName={dataSourceBatchName}
          dataSource={dataSource ?? apiDataLatest?.dataSource}
          style={{ flex: 1 }}
        />

        {canUseBCA(user?.email) ? (
          apiDataLatest?.dataSource?.primaryKeyConfigured ? (
            <div style={{ marginRight: "1rem" }}>
              <MdKey
                style={{ color: "green" }}
                title={"Has Primary Key Configured"}
              />
            </div>
          ) : (
            <div style={{ marginRight: "1rem" }}>
              <MdKey
                style={{ color: "orange" }}
                title={"Does Not Have Primary Key Configured"}
              />
            </div>
          )
        ) : null}
        <DataSourceImportance
          priorityLevel={priorityLevel}
          qualityImpact={qualityImpact}
          description={description}
          sourceId={sourceId}
        />
      </ImportanceContainer>

      <Tabs>
        <TabsLeft>
          <TabLink exact to={`/sources/${sourceId}`}>
            Report
          </TabLink>
          <TabLink exact to={`/sources/${sourceId}/failures`}>
            Failure Details
          </TabLink>
          <TabLink to={`/sources/${sourceId}/profile`}>Data Profile</TabLink>
          <TabLink to={`/sources/${sourceId}/policyprofile`}>
            Policy Profile
          </TabLink>
          <TabLink exact to={`/sources/${sourceId}/issues`}>
            Issues
          </TabLink>
          <TabLink
            isActive={(_, location) => {
              if (location?.pathname?.indexOf("/manage/") > -1) return true;
            }}
            to={`/sources/${sourceId}/manage/policies`}
          >
            Manage
          </TabLink>
        </TabsLeft>

        <TabsRight>
          {timestamp ? (
            <>
              <TimestampWrapper>
                {`Current report from ${timestamp}`}
              </TimestampWrapper>
              <TimestampSeparator>|</TimestampSeparator>
            </>
          ) : null}
          <StyledLink to={dataSourceReports(sourceId)}>
            Report History
          </StyledLink>
        </TabsRight>
      </Tabs>

      {isGeneratingCachedReport === true && (
        <div style={{ marginBottom: "1rem" }}>
          <ReportStatus>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              Your report is currently being generated... This may take a few
              minutes.
            </div>
          </ReportStatus>
        </div>
      )}

      <Switch>
        <Route
          path={`/sources/:sourceId/manage`}
          component={() => (
            <DashboardGrid>
              <Card
                titleDescription={""}
                body={() => (
                  <ManageSource
                    sourceId={sourceId}
                    reportAvailable={reportAvailable}
                  />
                )}
                headless={true}
              />
            </DashboardGrid>
          )}
        />

        <Route
          path={`/sources/:sourceId/failures`}
          component={() => (
            <FailureDetails
              apiData={apiData}
              errors={errors}
              sourceId={sourceId}
            />
          )}
        />

        <Route
          path={`/sources/:sourceId/profile`}
          component={() => (
            <DashboardGrid>
              <Card
                title={"Data Profile"}
                titleDescription={"Data Source Statistics"}
                body={() => <DataProfile sourceId={sourceId} data={apiData} />}
              />
            </DashboardGrid>
          )}
        />

        <Route
          path={`/sources/:sourceId/policyprofile`}
          component={() => (
            <DashboardGrid>
              <Card
                title={"Policy Profile"}
                titleDescription={"Data Source Policy Profile"}
                body={() => (
                  <PolicyProfile
                    sourceId={sourceId}
                    data={apiData}
                    reportId={
                      apiDataLatest?.dataSource?.latestReport?.refreshSummaryId
                    }
                  />
                )}
              />
            </DashboardGrid>
          )}
        />

        <Route
          path={`/sources/:sourceId/issues`}
          component={() => (
            <DashboardGrid>
              <Card
                title={"Issues"}
                titleDescription={""}
                body={() => <IssueBody sourceId={sourceId} />}
                headless={true}
              />
            </DashboardGrid>
          )}
        />

        <Route
          path={`/sources/:sourceId/`}
          component={() => (
            <>
              {!reportHasInstances ? (
                <CTAMessage
                  msg={
                    <div>
                      <p style={{ textAlign: "center" }}>
                        No Available Report, be sure to have added Rule
                        Standards and applied them to the Data Source. Also, be
                        sure the source is enabled.
                      </p>

                      {errorsLatest?.length ? (
                        <CTAMessage
                          msg={
                            <div>
                              <ErrorMessages errors={errorsLatest} />
                            </div>
                          }
                        />
                      ) : null}

                      {errors && isRegularErrors ? (
                        <ErrorMessages errors={errors} />
                      ) : null}
                    </div>
                  }
                />
              ) : (
                <>
                  {!reportAvailable ? (
                    <SplashLoader text="Report In Progress" />
                  ) : null}
                  <Report
                    sourceId={sourceId}
                    reportId={
                      apiDataLatest?.dataSource?.latestReport?.refreshSummaryId
                    }
                    reportData={reportData}
                    isGeneratingCachedReport={isGeneratingCachedReport}
                    loading={loading}
                    errors={errors}
                    errorsLatest={errorsLatest}
                    dataSourceName={dataSourceName}
                    fetchLatestReport={fetchLatestReport}
                    dataSource={dataSource}
                  />
                </>
              )}
            </>
          )}
        />
      </Switch>
    </div>
  );
};

export default Dashboard;
